import { corpCustomerBillingAccounts } from "@telia/b2b-rest-client";
import { IBillingAccount } from "../interfaces/IState";
import { downloadBlob } from "../utils/download-blob";
import { _mapGetAllBillingAccountsForTscidDTOToIBillingAccount } from "./mappers/billing-accounts-mappers";

export const fetchBillingAccount = (scopeId: string): Promise<IBillingAccount[] | []> => {
  return new Promise((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.getBillingAccountsForTscids(
      scopeId
    )
      .then((data: GetAllBillingAccountsForTscidDTO[]): void | PromiseLike<void> => {
        const mappedData: IBillingAccount[] =
          _mapGetAllBillingAccountsForTscidDTOToIBillingAccount(data);

        resolve(mappedData);
      })
      .catch((e) => reject(e));
  });
};

export const exportBillingAccounts = (
  scopeId: string,
  tscid: string[],
  exportBillingAccount: ExportBillingAccountRequestDTO
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    corpCustomerBillingAccounts.BillingAccountsControllerService.exportBillingAccounts(
      scopeId,
      tscid,
      exportBillingAccount
    )
      .then((response) => {
        const date = new Date();
        const filename = `billing-accounts_${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
        downloadBlob(response, filename);
        resolve();
      })
      .catch((error) => reject(error));
  });
};
