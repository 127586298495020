import { h, createApp } from "vue";

import singleSpaVue from "single-spa-vue";
import "./set-public-path";

import App from "./b2b-billing-account-page-vue3.vue";
import "@telia/b2x-input-suggestions";
import "@telia/b2x-spinner";
import "@telia/b2x-spinner";
import "@telia/b2x-sspa-link";
import "@telia/b2x-tooltip";
import "@telia/b2x-onboarding";
import "@telia/b2x-modal";
import "@telia/b2x-table";
import "@telia/b2x-skeleton-input";
import "@telia/b2x-active-filters";
import "@telia/b2x-paginator";
import "@telia/b2b-layout";

const vueLifecycles = singleSpaVue({
  createApp,
  appOptions: {
    render() {
      return h(App, {
        // single-spa props are available on the "this" object. Forward them to your component as needed.
        // https://single-spa.js.org/docs/building-applications#lifecyle-props
        // if you uncomment these, remember to add matching prop definitions for them in your App.vue file.
        /*
        name: this.name,
        mountParcel: this.mountParcel,
        singleSpa: this.singleSpa,
        */
      });
    },
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
