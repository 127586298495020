<script setup lang="ts">
import { ref, computed } from "vue";

import useBillingAccountState from "../../../composables/billing-account-state";

import { FILTER_KEY } from "./../enums/filter_keys";

import { translateMixin } from "./../../../locale/index";
import useActiveFilters from "../../../composables/active-filters";

const t = translateMixin.methods.t;
const inputValue = ref("");
const invoiceEmailAddressFilterRef = ref();
const emailAddressSuggestions = ref<string[]>([]);
const { billingAccounts, layoutState } = useBillingAccountState();
const { addFilter } = useActiveFilters();

const billingAccountsList = computed(() => {
  return billingAccounts.value.list;
});

const loading = computed(() => {
  return layoutState.value.billingAccounts.loading;
});

const disabled = computed(() => {
  return layoutState.value.loading;
});

const createEmailAddressSuggestions = () => {
  setLoading(true);
  const matchingAccounts = billingAccountsList.value.filter((account) =>
    account.billingAccountDelivery.invoiceDeliveryEmail
      .toLowerCase()
      .includes(inputValue.value.toLowerCase())
  );
  const emailAddresses = matchingAccounts
    .map((account) => account.billingAccountDelivery.invoiceDeliveryEmail)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  emailAddressSuggestions.value = [...new Set(emailAddresses)];
  setLoading(false);
};

const clearEmailAddressSuggestions = () => {
  emailAddressSuggestions.value = [];
};

const clearInput = () => {
  if (!invoiceEmailAddressFilterRef.value?.shadowRoot) return;
  invoiceEmailAddressFilterRef.value.shadowRoot.querySelector("input").value = "";
};

const setLoading = (isLoading: boolean) => {
  layoutState.value.billingAccounts.loading = isLoading;
};

const handleInputValue = (event: CustomEvent) => {
  inputValue.value = event.detail;
  if (inputValue.value.length > 2) {
    createEmailAddressSuggestions();
  } else {
    clearEmailAddressSuggestions();
  }
};

const handleSuggestionSelected = (event: CustomEvent) => {
  addFilter({
    key: FILTER_KEY.DELIVERY_EMAIL,
    value: event.detail,
  });
  clearInput();
  clearEmailAddressSuggestions();
};
</script>

<template>
  <div t-id="invoice-email-address-filter">
    <b2x-input-suggestions
      ref="invoiceEmailAddressFilterRef"
      :label="t('mybusiness.invoice.deliveryMethod.deliveryEmail')"
      :suggestions="JSON.stringify(emailAddressSuggestions)"
      :disabled="disabled"
      :loading="loading"
      @inputValue="handleInputValue"
      @suggestionSelected="handleSuggestionSelected"
    ></b2x-input-suggestions>
  </div>
</template>
