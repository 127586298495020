<script setup lang="ts">
import { computed } from "vue";

import useActiveFilters from "../../composables/active-filters";

import { FILTER_KEY } from "./enums/filter_keys";

import { translateMixin } from "../../locale";
import { translateInvoiceDeliveryMethod } from "../../utils/helper_utils";

const t = translateMixin.methods.t;

const { activeFilters, removeFilter, removeAllFilters } = useActiveFilters();

const activeFiltersWithoutOrg = computed(() => {
 return activeFilters.value.filter((filter) => filter.key !== FILTER_KEY.ORGANISATION);
});

const translatedActiveFilters = computed(() => {
  return activeFiltersWithoutOrg.value.map((filter) => {
    let value: string;
    switch (filter.key) {
      case FILTER_KEY.DELIVERY_METHOD:
        value = translateInvoiceDeliveryMethod(t, filter.value);
        break;
      default:
        value = filter.value;
        break;
    }
    return { ...filter, value: value };
  });
});
</script>

<template>
  <b2x-active-filters
    t-id="b2x-active-filters"
    :filter-list="JSON.stringify(translatedActiveFilters)"
    :show-active-filter="JSON.stringify(activeFiltersWithoutOrg.length > 0)"
    @clearAllFiltersEvent="removeAllFilters"
    @clearFilterEvent="(event: CustomEvent) => removeFilter(event.detail)"
  ></b2x-active-filters>
</template>
