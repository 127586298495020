<script setup lang="ts">
import { computed } from "vue";

import useBillingAccountState from "../../composables/billing-account-state";

import { translateMixin } from "../../locale";

const t = translateMixin.methods.t;
const { scopeId } = useBillingAccountState();

const askAQuestionUrl = computed(() => {
  return `/foretag/mybusiness/${scopeId.value}/support/arenden/fraga`;
});
</script>

<template>
  <div t-id="billing-account-list-empty-wrapper" class="b2b-billing-account-table__empty-list">
    <telia-heading tag="h1" variant="title-300" t-id="billing-account-list-empty-header">
      {{ t("table.empty.header") }}
    </telia-heading>
    <telia-p variant="preamble-100" t-id="billing-account-list-empty-ingress">
      {{ t("table.empty.ingress") }}
    </telia-p>
    <telia-p t-id="billing-account-list-empty-description">
      {{ t("table.empty.description") }}
    </telia-p>
    <b2x-sspa-link
      t-id="billing-account-list-empty-link"
      variant="standalone"
      target="_blank"
      :href="askAQuestionUrl"
    >
      {{ t("table.empty.link") }}
    </b2x-sspa-link>
  </div>
</template>

<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/breakpoints/variables";
div.b2b-billing-account-table__empty-list {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: $telia-spacing-48;
  telia-p {
    padding: $telia-spacing-20 0;
  }
}
</style>
