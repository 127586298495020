<script setup lang="ts">
import { translateMixin, translateSetup } from "./locale";
import AccountFilter from "./components/b2b-account-filter/account-filter.vue";
import AccountTable from "./components/b2b-account-table/account-table.vue";
import OnboardingModal from "./components/onboarding/onboarding-modal.vue";
import { onBeforeMount, ref } from "vue";
import useBillingAccountState from "./composables/billing-account-state";
import useOrganisations from "./composables/organisations";

onBeforeMount(async () => {
  await translateSetup(["mybusiness"]);
  reRenderKey.value++;
  await setOrganisations();
  await initializeData();  
  shouldShowOnboardingModal();
});

const reRenderKey = ref(0);

const t = translateMixin.methods.t;

const showOnboardingModal = ref(false);
const isWideTable = ref(false);
const { setOrganisations } = useOrganisations();
const { initializeData, setSelectedOrganisation } = useBillingAccountState();

const shouldShowOnboardingModal = () => {
  if (!window.localStorage.getItem("billingAccountOnboardingWatched")) {
    showOnboardingModal.value = true;
  }
};
</script>

<template>
  <section id="billingAccountTable" t-id="b2b-billing-account-page" :key="reRenderKey">
    <b2b-layout
      :showOrganisationSelector="true"
      :showOnboardingButton="true"
      :heading="t('mybusiness.invoice.management')"
      :pageTitle="t('mybusiness.invoice.management') || 'Manage invoices'"
      :enable-all-organisations="true"
      @organisationSelected="setSelectedOrganisation"
      @onboardingButtonClicked="showOnboardingModal = true"
    >
      <account-filter></account-filter>
      <div :class="[isWideTable ? 'telia-grid-wrapper wide-table' : 'telia-grid-wrapper']" t-id="table-grid-wrapper">
        <telia-grid>
          <telia-row>
            <telia-col width="12">
              <account-table
                :is-wide-table="isWideTable"
                @toggleIsWideTable="isWideTable = !isWideTable"
              />
            </telia-col>
          </telia-row>
        </telia-grid>
      </div>    
    </b2b-layout>
    <onboarding-modal
      v-if="showOnboardingModal"
      @closeOnboardingModal="showOnboardingModal = false"
    />
  </section>
</template>

<style lang="scss" scoped>
@mixin wide-table {
  .telia-grid-wrapper telia-grid {
    transition: max-width 0.3s ease-in;
  }

  .telia-grid-wrapper.wide-table telia-grid {
    max-width: 100%;
  }
}

section#billingAccountTable {
  @include wide-table;
}
</style>
