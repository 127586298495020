export const downloadBlob = (blobString: string, fileName: string): void => {
  // we need this to have correctly encoded swedish letters
  const encoding = new Uint8Array([0xef, 0xbb, 0xbf]);
  const blob = new Blob([encoding, blobString], {
    type: "text/csv;charset-utf-8;",
  });

  const url = window.URL.createObjectURL(blob);

  const a = window.document.createElement("a");
  window.document.body.appendChild(a);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
};
