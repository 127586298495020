<script setup lang="ts">
import { ref, computed } from "vue";
import useActiveFilters from "../../../composables/active-filters";
import useBillingAccountState from "../../../composables/billing-account-state";
import { translateMixin } from "../../../locale/index";
import { FILTER_KEY } from "../enums/filter_keys";

const t = translateMixin.methods.t;
const inputValue = ref("");
const accountNumberSuggestions = ref<string[]>([]);
const billingAccountNumberFilterRef = ref();
const { billingAccounts, layoutState } = useBillingAccountState();
const { addFilter } = useActiveFilters();

const billingAccountsList = computed(() => {
  return billingAccounts.value.list;
});

const loading = computed(() => {
  return layoutState.value.billingAccounts.loading;
});

const disabled = computed(() => {
  return layoutState.value.loading;
});

const createAccountNumberSuggestions = () => {
  setLoading(true);
  const matchingAccounts = billingAccountsList.value
    .filter((account) =>
      account.accountNumber.toLowerCase().includes(inputValue.value.toLowerCase())
    )
    .map((account) => account.accountNumber)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  accountNumberSuggestions.value = matchingAccounts;
  setLoading(false);
};

const createNumberSuggestions = () => {
  setLoading(true);
  const matchingAccounts = billingAccountsList.value.filter((account) =>
    account.number.toLowerCase().includes(inputValue.value.toLowerCase())
  );
  accountNumberSuggestions.value = matchingAccounts
    .map((account) => account.number)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  setLoading(false);
};

const clearAccountNumberSuggestions = () => {
  accountNumberSuggestions.value = [];
};

const clearInput = () => {
  if (!billingAccountNumberFilterRef.value.shadowRoot) return;
  billingAccountNumberFilterRef.value.shadowRoot.querySelector("input").value = "";
};

const setLoading = (isLoading: boolean) => {
  layoutState.value.billingAccounts.loading = isLoading;
};

const handleInputValue = (event: CustomEvent) => {
  inputValue.value = event.detail;
  if (inputValue.value.length > 2) {
    event.detail.toLowerCase().startsWith("m")
      ? createNumberSuggestions()
      : createAccountNumberSuggestions();
  } else {
    clearAccountNumberSuggestions();
  }
};

const handleSuggestionSelected = (event: CustomEvent) => {
  //TODO temporarily code until we can remove M-numbers (remove in MYBD-16037). If statement should be removed and only filter on ACCOUNT_NUMBER
  const filter_key = event.detail.toLowerCase().startsWith("m")
    ? FILTER_KEY.NUMBER
    : FILTER_KEY.ACCOUNT_NUMBER;
  addFilter({
    key: filter_key,
    value: event.detail,
  });
  clearInput();
  clearAccountNumberSuggestions();
};
</script>

<template>
  <div t-id="billing-account-number-filter">
    <b2x-input-suggestions
      ref="billingAccountNumberFilterRef"
      :label="t('mybusiness.billing.account')"
      :suggestions="JSON.stringify(accountNumberSuggestions)"
      :disabled="disabled"
      :loading="loading"
      @inputValue="handleInputValue"
      @suggestionSelected="handleSuggestionSelected"
    ></b2x-input-suggestions>
  </div>
</template>
