<script setup lang="ts">
import { logError } from "@telia/b2x-logging";
import { ref, computed, watch, onMounted } from "vue";
import useActiveFilters from "../../composables/active-filters";

import useBillingAccountState from "../../composables/billing-account-state";
import usePagination from "../../composables/pagination";
import {
  IAccountNumberLink,
  IBillingAccount,
  ICheckedColumn,
  ISortableColumns,
  ITableHeader,
  IUserGetData,
  TableData,
} from "../../interfaces/IState";

import { translateMixin } from "../../locale/index";
import { getData, saveUserData } from "../../services/corp-miscellaneous-data";

import {
  analytics,
  trackEventInvoiceAccounts,
  trackEventTableWidthButton,
} from "../../utils/analytics";
import { createSortableColumns } from "../../utils/create-state";
import {
  getStringSortNumber,
  defineColumnSortStates,
  createTableHeaders,
  setupColumnSortingKeys,
  defaultCheckedColumns,
  translateInvoiceDeliveryMethod,
} from "../../utils/helper_utils";
import EmptyTable from "./empty-table.vue";
import { getScopeId } from "@telia/b2b-customer-scope";

const props = withDefaults(defineProps<{ isWideTable: boolean }>(), {
  isWideTable: false,
});
const emit = defineEmits<{ (e: "toggleIsWideTable"): void }>();
const scopeId = ref<string>("");

onMounted(async () => {
  scopeId.value = (await getScopeId()) ?? "";
  await setupCheckedColumns();
  setupHeaders();
});

const t = translateMixin.methods.t;
const sortableColumns = ref<ISortableColumns>(createSortableColumns());
const columnSortingKeys = ref<Record<string, string>>({});
const columnSortStates = defineColumnSortStates(sortColumnDescending, sortColumnAscending);
const tableData = ref<TableData>([]);
const tableHeaders = ref<ITableHeader[]>([]);
const checkedColumns = ref<ICheckedColumn[]>([]);

const types = ref([".csv"]);
const { layoutState, billingAccounts, exportBillingAccounts } = useBillingAccountState();
const { pagination, paginationChange, setPaginationToPageOne } = usePagination();

const { activeFilters } = useActiveFilters();

const headers = computed(() => {
  if (checkedColumns.value.length === 0) return [];
  return tableHeaders.value;
});

const loadingTemplate = computed(() => {
  return layoutState.value.loadingTemplate;
});

const emptyList = computed(() => {
  return !layoutState.value.loading && tableData.value.length === 0;
});

const pageTotal = computed(() => {
  return pagination.value.pageTotal;
});

const setupHeaders = (): void => {
  tableHeaders.value = createTableHeaders(
    t,
    sortableColumns.value.default.iconName,
    checkedColumns.value
  );

  columnSortingKeys.value = setupColumnSortingKeys(t);
};

const setupCheckedColumns = async (): Promise<void> => {
  const userSavedDataResponse: IUserGetData = await getData(scopeId.value);

  checkedColumns.value = checkIfUserHasSavedData(userSavedDataResponse)
    ? userSavedDataResponse.data.checkedColumns
    : defaultCheckedColumns();
};

const resetCheckedColumns = (): void => {
  checkedColumns.value = defaultCheckedColumns();
  tableHeaders.value.map((header) => {
    header.isChecked = true;
  });

  _saveUserData();
};

const checkIfUserHasSavedData = (userSavedDataResponse: IUserGetData): boolean => {
  return userSavedDataResponse?.data?.checkedColumns?.length === defaultCheckedColumns().length;
};

const toggleCheckedColumns = (columnName: string): void => {
  const columnIndex = getCheckedColumnIndex(columnName);

  checkedColumns.value[columnIndex].isChecked = !checkedColumns.value[columnIndex].isChecked;
  tableHeaders.value[columnIndex].isChecked = !tableHeaders.value[columnIndex].isChecked;

  _saveUserData();
};

const getCheckedColumnIndex = (columnName: string): number => {
  return checkedColumns.value.findIndex((col: ICheckedColumn) => t(col.title) === columnName);
};

const headerClicked = (event: CustomEvent) => {
  sortTableData(event.detail);
};

const resetButtonClick = () => {
  resetCheckedColumns();
};

const checkBoxSelect = (event: CustomEvent) => {
  toggleCheckedColumns(event.detail);
};

const controlledTableExpandClicked = () => {
  const text = props.isWideTable ? "Minimize" : "Expand";
  trackEventTableWidthButton(text);
  emit("toggleIsWideTable");
};

const exportData = () => {
  exportBillingAccounts();
};

const sortTableData = (columnName: string): void => {
  if (sortableColumns.value.active.activeName === columnName) {
    generateTableData();
  } else {
    sortableColumns.value.active.activeName = columnName;
    generateTableData(true, true);
  }
  trackEventInvoiceAccounts(
    `${sortableColumns.value.active.activeName} Column - ${sortableColumns.value.active.state}`,
    analytics.action.SORT
  );
};

const generateTableData = (switchSortingState = true, firstClickOnNewHeader = false): void => {
  tableData.value = tryToFilterSortAndMapTableData(switchSortingState, firstClickOnNewHeader);
  if (tableData.value.length === 0) {
    trackEventInvoiceAccounts("Empty search results", analytics.action.FILTER);
  }
};

const tryToFilterSortAndMapTableData = (
  switchSortingState: boolean,
  firstClickOnNewHeader: boolean
): (string | number | IAccountNumberLink | undefined)[][] => {
  try {
    layoutState.value.error = false;
    const filteredOnActiveFiltersAccounts = filterOnActiveFilters();

    pagination.value.pageTotal = filteredOnActiveFiltersAccounts.length;

    if (sortableColumns.value.active.activeName !== "") {
      sortTableDataOnColumn(
        filteredOnActiveFiltersAccounts,
        switchSortingState,
        firstClickOnNewHeader
      );
    }
    const filteredOnActiveFiltersAccountsAndPaginated = filterOnPaginationValues(
      filteredOnActiveFiltersAccounts
    );

    return mapAccountsToTabledata(filteredOnActiveFiltersAccountsAndPaginated);
  } catch (e) {
    layoutState.value.error = true;
    _logError("Failed to filter, sort and map table data");
    return [];
  }
};

const filterOnActiveFilters = (): Array<IBillingAccount> => {
  return billingAccounts.value.list.filter((account) => {
    return activeFilters.value.every((activeFilter) => {
      let keyValue = getKeyValueInAccount(activeFilter.key, account);

      if (activeFilter.key === "organisation.organisationNumber") {
        keyValue = keyValue.replace("-", "");
      }

      return (keyValue as string).toLowerCase() === activeFilter.value.toLowerCase();
    });
  });
};

const sortTableDataOnColumn = (
  filteredAccounts: GetAllBillingAccountsForTscidDTO[],
  switchSortingState: boolean,
  firstClickOnNewHeader: boolean
): void => {
  const currentColumnSortState = setCurrentColumnSortState(
    firstClickOnNewHeader,
    switchSortingState
  );
  resetAndSetActiveFilterIconName();

  if (currentColumnSortState.sortingFunction) {
    filteredAccounts.sort(currentColumnSortState.sortingFunction);
  }
};

const filterOnPaginationValues = (accounts: IBillingAccount[]) => {
  const getBillingAccountFrom = calculateBillingAccountFrom();
  const getBillingAccountTo = calculateBillingAccountTo();
  return accounts.filter(
    (account: IBillingAccount, index: number) =>
      index >= getBillingAccountFrom && index < getBillingAccountTo
  );
};

const mapAccountsToTabledata = (accounts: IBillingAccount[]) => {
  return accounts.map((account: IBillingAccount) => {
    return [
      mapAccountNumber(account.accountNumber, account.organisation?.tscid, account.source),
      account.subscriptionCount,
      account.accountReference,
      account.accountCO,
      account.organisation?.billingName,
      account.organisation?.organisationNumber,
      account.billingAddress?.address,
      translateInvoiceDeliveryMethod(t, account.billingAccountDelivery?.invoiceDeliveryMethod),
      account.billingAccountDelivery?.invoiceDeliveryEmail,
      account.source,
    ];
  });
};

const _logError = (message: string): void => {
  logError("b2b-billing-account-page", message);
};

const getKeyValueInAccount = (key: string, account: GetAllBillingAccountsForTscidDTO): string => {
  return key.split(".").reduce((attr, key) => {
    return attr[key];
  }, account) as string;
};

const setCurrentColumnSortState = (firstClickOnNewHeader: boolean, switchSortingState: boolean) => {
  let currentColumnSortState = columnSortStates[sortableColumns.value.active.state];
  if (firstClickOnNewHeader) {
    currentColumnSortState = columnSortStates["descending"];
    sortableColumns.value.active.state = currentColumnSortState.state;
    sortableColumns.value.active.iconName = currentColumnSortState.iconName;
  } else if (switchSortingState) {
    currentColumnSortState = columnSortStates[currentColumnSortState.next];
    sortableColumns.value.active.state = currentColumnSortState.state;
    sortableColumns.value.active.iconName = currentColumnSortState.iconName;
  }
  return currentColumnSortState;
};

const resetAndSetActiveFilterIconName = () => {
  tableHeaders.value.map((header: ITableHeader) => {
    header.sortable.sortIconName =
      header.title === sortableColumns.value.active.activeName
        ? sortableColumns.value.active.iconName
        : "sorter";
  });
};

const mapAccountNumber = (
  billingAccountNumber: string,
  tscid: string,
  source: string
): IAccountNumberLink => {
  const url = `/foretag/mybusiness/${scopeId.value}/fakturor/hantera-fakturering/${tscid}/${billingAccountNumber}?source=${source}`;
  return {
    href: url,
    content: billingAccountNumber,
    disableVisited: true,
  };
};

const calculateBillingAccountFrom = (): number => {
  const endBillingAccountNumber = calculateBillingAccountTo();
  const numberOfBillingAccounts = pagination.value.pageSize;
  const startBillingAccountFrom = endBillingAccountNumber - numberOfBillingAccounts;
  return startBillingAccountFrom;
};

const calculateBillingAccountTo = (): number => {
  const endBillingAccountNumber = pagination.value.pageSize * pagination.value.page;
  return endBillingAccountNumber;
};

function sortColumnDescending(
  row1: GetAllBillingAccountsForTscidDTO,
  row2: GetAllBillingAccountsForTscidDTO
): number {
  const row1Value = getRowValue(row1);
  const row2Value = getRowValue(row2);

  return getStringSortNumber(row2Value, row1Value);
}

function sortColumnAscending(
  row1: GetAllBillingAccountsForTscidDTO,
  row2: GetAllBillingAccountsForTscidDTO
): number {
  const row1Value = getRowValue(row1);
  const row2Value = getRowValue(row2);

  return getStringSortNumber(row1Value, row2Value);
}

const getRowValue = (row: GetBillingAccountsForTscidDTO): string => {
  let rowValue = getKeyValueInAccount(
    columnSortingKeys.value[sortableColumns.value.active.activeName],
    row
  );

  if (typeof rowValue === "string") {
    rowValue = rowValue.toLocaleLowerCase();
  }
  return rowValue;
};

const resetSorting = () => {
  sortableColumns.value.active = {
    state: "default",
    iconName: "sorter",
    activeName: "",
    activeSortingKey: "",
  };

  tableHeaders.value
    .filter((tableHeader: ITableHeader) => {
      return tableHeader.sortable.sortIconName !== "sorter";
    })
    .map((tableHeader: ITableHeader) => {
      tableHeader.sortable.sortIconName = "sorter";
    });
};

const handlePaginationChange = (event: CustomEvent) => {
  paginationChange(event.detail);
};

const saveUserDataTimeout = ref<ReturnType<typeof setTimeout> | undefined>();
const _saveUserData = () => {
  if (saveUserDataTimeout.value) clearTimeout(saveUserDataTimeout.value);
  saveUserDataTimeout.value = setTimeout(() => {
    saveUserData({ checkedColumns: checkedColumns.value }, scopeId.value);
  }, 2000);
};

watch(
  billingAccounts.value,
  () => {
    resetSorting();
    generateTableData();
  },
  { immediate: true }
);

watch(
  () => activeFilters.value,
  () => {
    resetSorting();
    setPaginationToPageOne();
    generateTableData();
  }
);

watch(pagination.value, () => {
  generateTableData(false);
});
</script>

<template>
  <section id="accountTable" t-id="account-table">
    <b2x-controlled-table
      :columns="JSON.stringify(headers)"
      :data="JSON.stringify(tableData)"
      :column-filter-label="t('mybusiness.columnManager')"
      :reset-column-label="t('mybusiness.resetColumns')"
      show-expand-button="true"
      :show-filters="JSON.stringify(true)"
      :is-wide-table="isWideTable"
      :is-loading="loadingTemplate"
      @columnClicked="headerClicked"
      @resetButtonClick="resetButtonClick"
      @checkBoxSelect="checkBoxSelect"
      @controlledTableExpandClicked="controlledTableExpandClicked"
      :export-btn-label="t('mybusiness.export')"
      :export-types="JSON.stringify(types)"
      :export-line-label="t('mybusiness.exportAs')"
      @exportData="exportData"
    />

    <b2x-paginator
      v-if="!emptyList"
      :list-length="pageTotal"
      :page-sizes="'[10, 25, 50, 100]'"
      :default-page-size="pagination.pageSize"
      t-id="b2x-paginator"
      @paginationChange="handlePaginationChange"
    ></b2x-paginator>
    <EmptyTable v-if="emptyList" />
  </section>
</template>

<style scoped lang="scss">
section#accountTable {
  margin-top: 3rem;
}
</style>
