<script setup lang="ts">
import { ref, computed } from "vue";

import useBillingAccountState from "../../composables/billing-account-state";

import InvoiceAddress from "./filters/invoice-address.vue";
import AccountCOFilter from "./filters/account-co-filter.vue";
import EmailAddressFilter from "./filters/email-address-filter.vue";
import OrganisationsFilter from "./filters/organisations-filter.vue";
import DeliveryMethodFilter from "./filters/delivery-method-filter.vue";
import AccountReferenceFilter from "./filters/account-reference-filter.vue";
import BillingAccountNumberFilter from "./filters/billing-account-number-filter.vue";

import { translateMixin } from "../../locale";

import ActiveFilters from "./active-filters.vue";

const t = translateMixin.methods.t;
const legendText = ref(`${t("mybusiness.search")} ${t("mybusiness.billing.account")}`);
const { layoutState } = useBillingAccountState();

const loading = computed(() => {
  return layoutState.value.loading;
});
</script>

<template>
  <telia-fieldset t-id="filter-fieldset" :disabled="loading" :legend="legendText">
    <div class="account-filter__options">
      <billing-account-number-filter></billing-account-number-filter>
      <invoice-address></invoice-address>
      <delivery-method-filter></delivery-method-filter>
      <email-address-filter></email-address-filter>
      <account-reference-filter></account-reference-filter>
      <account-c-o-filter></account-c-o-filter>
    </div>
    <active-filters></active-filters>
  </telia-fieldset>
</template>

<style scoped lang="scss"></style>
