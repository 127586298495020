<template>
  <div>
    <telia-skeleton class="skeleton-legend" t-id="filter-skeleton-legend"></telia-skeleton>
    <div class="account-filter__options">
      <b2x-skeleton-input></b2x-skeleton-input>
      <b2x-skeleton-input></b2x-skeleton-input>
      <b2x-skeleton-input></b2x-skeleton-input>
      <b2x-skeleton-input></b2x-skeleton-input>
      <b2x-skeleton-input></b2x-skeleton-input>
      <b2x-skeleton-input></b2x-skeleton-input>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "~@teliads/components/foundations/spacing/variables.scss";

telia-skeleton.skeleton-legend {
  width: 20rem;
  height: 2.9rem;
  margin-bottom: $telia-spacing-24;
}
</style>
