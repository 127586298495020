import {
  action,
  category,
  customDimensions,
  label,
  trackEvent as TrackEvent,
} from "@telia/b2b-web-analytics-wrapper";
import { ICustomDimension } from "@telia/b2b-web-analytics-wrapper/types/interfaces";

const ANALYTICS_LABEL_EXPORT_CSV = "Export csv";

export const analytics = { category, action, label, customDimensions };

export const trackEventInvoiceAccounts = async (
  label: string,
  action: string,
  customDimensions: ICustomDimension[] | undefined = undefined,
  value?: string | undefined
): Promise<void> => {
  await TrackEvent(analytics.category.INVOICE_ACCOUNTS, action, label, value, customDimensions);
};

export const trackEventOnboardingModal = (label: string, action: string): void => {
  TrackEvent(analytics.category.INVOICE_ACCOUNT_ONBOARDING_MODAL, action, label);
};

export const trackOnboardingModalStepsGa = (label: string): void => {
  TrackEvent(
    analytics.category.INVOICE_ACCOUNT_ONBOARDING_MODAL,
    analytics.action.CLICK,
    analytics.label[label]
  );
};

export const trackEventTableWidthButton = (toggleTableWidthButtonText: string): void => {
  trackEventInvoiceAccounts(`Table width - ${toggleTableWidthButtonText}`, analytics.action.CLICK);
};

export const trackEventExportBillingAccounts = (action: string) => {
  trackEventInvoiceAccounts(ANALYTICS_LABEL_EXPORT_CSV, action);
};
