<script setup lang="ts">
import { ref, computed } from "vue";

import useBillingAccountState from "../../../composables/billing-account-state";

import { FILTER_KEY } from "./../enums/filter_keys";

import { translateMixin } from "./../../../locale/index";
import useActiveFilters from "../../../composables/active-filters";

const t = translateMixin.methods.t;
const { billingAccounts, layoutState } = useBillingAccountState();
const { addFilter } = useActiveFilters();

const invoiceAddressSuggestions = ref<string[]>([]);
const inputValue = ref("");
const invoiceAddressFilterRef = ref();

const billingAccountsList = computed(() => {
  return billingAccounts.value.list;
});

const loading = computed(() => {
  return layoutState.value.billingAccounts.loading;
});

const disabled = computed(() => {
  return layoutState.value.loading;
});

const createInvoiceAddressSuggestions = () => {
  setLoading(true);
  const matchingAccounts = billingAccountsList.value.filter((account) =>
    account.billingAddress.address.toLowerCase().includes(inputValue.value.toLowerCase())
  );
  const invoiceAddresses = matchingAccounts
    .map((account) => account.billingAddress.address)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

  invoiceAddressSuggestions.value = [...new Set(invoiceAddresses)];
  setLoading(false);
};

const clearInvoiceAddressSuggestions = () => {
  invoiceAddressSuggestions.value = [];
};

const clearInput = () => {
  if (!invoiceAddressFilterRef.value?.shadowRoot) return;
  invoiceAddressFilterRef.value.shadowRoot.querySelector("input").value = "";
};

const setLoading = (isLoading: boolean) => {
  layoutState.value.billingAccounts.loading = isLoading;
};

const handleInputValue = (event: CustomEvent) => {
  inputValue.value = event.detail;
  if (inputValue.value.length > 2) {
    createInvoiceAddressSuggestions();
  } else {
    clearInvoiceAddressSuggestions();
  }
};

const handleSuggestionSelected = (event: CustomEvent) => {
  addFilter({
    key: FILTER_KEY.BILLING_ADDRESS,
    value: event.detail,
  });
  clearInput();
  clearInvoiceAddressSuggestions();
};
</script>

<template>
  <div t-id="invoice-address-filter">
    <b2x-input-suggestions
      ref="invoiceAddressFilterRef"
      :label="t('mybusiness.invoice.address')"
      :suggestions="JSON.stringify(invoiceAddressSuggestions)"
      :disabled="disabled"
      :loading="loading"
      @inputValue="handleInputValue"
      @suggestionSelected="handleSuggestionSelected"
    ></b2x-input-suggestions>
  </div>
</template>
