<script setup lang="ts">
import { ref, onMounted, nextTick } from "vue";

import onboarding_0_poster from "./img/onboarding_faktura_1_1500x800.jpg";
import onboarding_1_poster from "./img/onboarding_faktura_2_1500x800.jpg";
import onboarding_2_poster from "./img/onboarding_faktura_3_1500x800.jpg";
import onboarding_3_poster from "./img/onboarding_faktura_4_1500x800.jpg";
import onboarding_4_poster from "./img/onboarding_faktura_5_1500x800.jpg";

import { translateMixin } from "../../locale";

import onboarding_0 from "./videos/onboarding_faktura_1_1500x800.mp4";
import onboarding_1 from "./videos/onboarding_faktura_2_1500x800.mp4";
import onboarding_2 from "./videos/onboarding_faktura_3_1500x800.mp4";
import onboarding_3 from "./videos/onboarding_faktura_4_1500x800.mp4";
import onboarding_4 from "./videos/onboarding_faktura_5_1500x800.mp4";
import {
  analytics,
  trackEventOnboardingModal,
  trackOnboardingModalStepsGa,
} from "../../utils/analytics";

onMounted(async () => {
  await nextTick();
  isOpen.value = true;
  trackOpenModal();
});

const emit = defineEmits<{ (e: "closeOnboardingModal"): void }>();

const isOpen = ref(false);
const t = translateMixin.methods.t;

const steps = ref([
  { video: onboarding_0, poster: onboarding_0_poster },
  { video: onboarding_1, poster: onboarding_1_poster },
  { video: onboarding_2, poster: onboarding_2_poster },
  { video: onboarding_3, poster: onboarding_3_poster },
  { video: onboarding_4, poster: onboarding_4_poster },
]);

const onCloseModal = () => {
  window.localStorage.setItem("billingAccountOnboardingWatched", "1");
  isOpen.value = false;
  trackCloseModal();
  emit("closeOnboardingModal");
};

const trackOpenModal = () => {
  trackEventOnboardingModal(analytics.label.OPEN, analytics.action.OPEN);
};

const trackCloseModal = () => {
  trackEventOnboardingModal(analytics.label.CLOSED, analytics.action.CLICK);
};

const trackOnboardingNextStep = () => {
  trackOnboardingModalStepsGa("NEXT");
};

const trackOnboardingPreviousStep = () => {
  trackOnboardingModalStepsGa("PREVIOUS");
};
</script>

<template>
  <div>
    <b2x-onboarding
      :is-open="isOpen"
      @closeModal="onCloseModal"
      @nextStepClick="trackOnboardingNextStep"
      @previousStepClick="trackOnboardingPreviousStep"
    >
      <b2x-onboarding-step
        :key="stepIndex"
        v-for="(step, stepIndex) in steps"
        :video-src="step.video"
        :poster="step.poster"
        :heading="t(`onboardingModal.slide_${stepIndex}_title`)"
        :description="t(`onboardingModal.slide_${stepIndex}_desc`)"
      ></b2x-onboarding-step>
    </b2x-onboarding>
  </div>
</template>
