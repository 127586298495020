export enum FILTER_KEY {
  ACCOUNT_REFERENCE = "accountReference",
  ACCOUNT_CO = "accountCO",
  DELIVERY_METHOD = "billingAccountDelivery.invoiceDeliveryMethod",
  ORGANISATION = "organisation.organisationNumber",
  ACCOUNT_NUMBER = "accountNumber",
  NUMBER = "number",
  BILLING_ADDRESS = "billingAddress.address",
  DELIVERY_EMAIL = "billingAccountDelivery.invoiceDeliveryEmail",
}
