<script setup lang="ts">
import { computed } from "vue";

import useBillingAccountState from "../../composables/billing-account-state";

import FilterSkeletons from "./filter-skeletons.vue";
import AccountFilterContent from "./account-filters-content.vue";

const { layoutState } = useBillingAccountState();

const loadingTemplate = computed(() => {
  return layoutState.value.loadingTemplate;
});

const loadTemplate = computed(() => {
  return loadingTemplate.value ? FilterSkeletons : AccountFilterContent;
});
</script>

<template>
  <section id="accountFilter" t-id="account-filter">
    <telia-grid>
      <component :is="loadTemplate"></component>
    </telia-grid>
  </section>
</template>

<style module lang="scss">
@import "~@teliads/components/foundations/colors/variables.scss";
@import "~@teliads/components/foundations/typography/variables.scss";
@import "~@teliads/components/foundations/spacing/variables.scss";
@import "~@teliads/components/foundations/breakpoints/variables.scss";

section#accountFilter {
  padding-top: $telia-spacing-12;
  font-family: $telia-typography-telia-sans-font-family;

  div.account-filter__options {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 1rem;

    > * {
      min-height: $telia-spacing-96;
    }

    @media screen and (min-width: $telia-breakpoint-medium) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (min-width: $telia-breakpoint-large) {
      grid-template-columns: repeat(6, 1fr);
      div.subscription-number-tooltip__wrapper {
        grid-column: 3/5;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }
    }
  }
}
</style>
