import { corpMiscellaneousData } from "@telia/b2b-rest-client";
import { IUserGetData, IUserSaveData } from "../interfaces/IState";

const KEY = "billing-account-page";
const VERSION_NUMBER = "2";

export const getData = (scopeId: string): Promise<IUserGetData> => {
  return new Promise<IUserGetData>((resolve, reject) => {
    corpMiscellaneousData.PublicControllerService.getData(scopeId, KEY, VERSION_NUMBER)
      .then((response) => resolve((response as unknown) as IUserGetData))
      .catch((error) => reject(error));
  });
};

export const saveUserData = (
  userData: IUserSaveData,
  scopeId: string
): Promise<corpMiscellaneousData.StatusMessage> => {
  return new Promise<corpMiscellaneousData.StatusMessage>((resolve, reject) => {
    corpMiscellaneousData.PublicControllerService.save(scopeId, KEY, VERSION_NUMBER, userData)
      .then((response) => resolve(response))
      .catch((error) => reject(error));
  });
};
