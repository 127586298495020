import { corpCustomerBillingAccounts } from "@telia/b2b-rest-client";
import { IBillingAccount } from "../../interfaces/IState";

export const _mapGetAllBillingAccountsForTscidDTOToIBillingAccount = (
  data: GetAllBillingAccountsForTscidDTO[]
): IBillingAccount[] => {
  return data.map(
    (account: GetAllBillingAccountsForTscidDTO): IBillingAccount => ({
      accountNumber: account.accountNumber || "",
      number: account.number || "",
      accountReference: account.accountReference || "",
      accountCO: account.accountCO || "",
      billingAccountCategory: account.billingAccountCategory || "",
      billingAccountDelivery: {
        invoiceDeliveryEmail: account.billingAccountDelivery?.invoiceDeliveryEmail || "",
        invoiceDeliveryMethod: account.billingAccountDelivery?.invoiceDeliveryMethod || "UNKNOWN",
      },
      billingAddress: {
        address: _getBillingAddress(account),
        name: account.billingAddress?.name || "",
      },
      organisation: {
        billingName: account.organisation?.billingName || "",
        organisationNumber: account.organisation?.organisationNumber || "",
        tscid: account.organisation?.tscid || "",
      },
      subscriptionCount: account.subscriptionCount || 0,
      source: account.source || "",
    })
  );
};

const _getBillingAddress = (account: corpCustomerBillingAccounts.GetBillingAccountDTO): string => {
  return `${
    account.billingAddress?.address ? account.billingAddress?.address?.concat(",") : ""
  } ${_parseZipCode(account.billingAddress?.zipCode || "")} ${account.billingAddress?.city || ""}`;
};

const _parseZipCode = (zipCode: string): string => {
  return zipCode.replace(/(.{3})/, "$1 ");
};
