import { ref } from "vue";

import { getOrganizations } from "@telia/b2b-logged-in-service";
import { IOrganisations, IOrganisation } from "../interfaces/IState";
import { createOrganisationsState } from "../utils/create-state";
import { getStringSortNumber } from "../utils/helper_utils";

const organisations = ref<IOrganisations>(createOrganisationsState());

const useOrganisations = () => {
  const setOrganisations = async (): Promise<void> => {
    const orgs = (await getOrganizations()).map((org) => ({
      name: org.name ?? "",
      number: org.organizationNumber ?? "",
      tscid: org.tscid ?? "",
    }));

    if (orgs.length <= 1) {
      organisations.value.isVisible = false;
    }

    orgs.sort((a: IOrganisation, b: IOrganisation) => {
      const nameOne = a.name.toUpperCase();
      const nameTwo = b.name.toUpperCase();
      return getStringSortNumber(nameOne, nameTwo);
    });

    orgs.forEach((org: IOrganisation) => {
      organisations.value.tscidList.push(org.tscid);
    });
    organisations.value.list = orgs;
  };

  const getOrganisationName = (organisationNumber: string): string => {
    return (
      organisations.value.list.find((org: IOrganisation) => org.number === organisationNumber)
        ?.name ?? ""
    );
  };

  return { organisations, setOrganisations, getOrganisationName };
};

export default useOrganisations;
