import { ICheckedColumn, IColumnSortStates, ITableHeader } from "../interfaces/IState";

export const defaultCheckedColumns = (): ICheckedColumn[] => [
  { title: "mybusiness.billing.account", isChecked: true },
  { title: "mybusiness.invoice.numberOfSubscriptions", isChecked: true },
  { title: "mybusiness.invoice.organisation.accountReference", isChecked: true },
  { title: "mybusiness.invoice.organisation.accountCO", isChecked: true },
  { title: "mybusiness.invoice.organisation", isChecked: true },
  { title: "mybusiness.invoice.organisation.number", isChecked: true },
  { title: "mybusiness.invoice.address", isChecked: true },
  { title: "mybusiness.invoice.deliveryMethod", isChecked: true },
  { title: "mybusiness.invoice.deliveryMethod.deliveryEmail", isChecked: true },
];

export const setupColumnSortingKeys = (
  translate: (key: string, optionalObject?: Record<string, string | number>) => string
): Record<string, string> => ({
  [translate("mybusiness.billing.account")]: "accountNumber",
  [translate("mybusiness.invoice.numberOfSubscriptions")]: "subscriptionCount",
  [translate("mybusiness.invoice.organisation.accountReference")]: "accountReference",
  [translate("mybusiness.invoice.organisation.accountCO")]: "accountCO",
  [translate("mybusiness.invoice.organisation")]: "organisation.billingName",
  [translate("mybusiness.invoice.organisation.number")]: "organisation.organisationNumber",
  [translate("mybusiness.invoice.address")]: "billingAddress.address",
  [translate("mybusiness.invoice.deliveryMethod")]: "billingAccountDelivery.invoiceDeliveryMethod",
  [translate(
    "mybusiness.invoice.deliveryMethod.deliveryEmail"
  )]: "billingAccountDelivery.invoiceDeliveryEmail",
});

export const createTableHeaders = (
  translate: (key: string, optionalObject?: Record<string, string | number>) => string,
  defaultIconName: string,
  checkedColumn: ICheckedColumn[]
): ITableHeader[] => [
  {
    title: translate("mybusiness.billing.account"),
    type: "sspa-link",
    sortable: {
      sortIconName: defaultIconName,
    },
    isChecked: checkedColumn[0].isChecked,
    disabled: true,
  },
  {
    title: translate("mybusiness.invoice.numberOfSubscriptions"),
    type: "text",
    sortable: {
      sortIconName: defaultIconName,
    },
    isChecked: checkedColumn[1].isChecked,
  },
  {
    title: translate("mybusiness.invoice.organisation.accountReference"),
    type: "text",
    sortable: {
      sortIconName: defaultIconName,
    },
    isChecked: checkedColumn[2].isChecked,
  },
  {
    title: translate("mybusiness.invoice.organisation.accountCO"),
    type: "text",
    sortable: {
      sortIconName: defaultIconName,
    },
    isChecked: checkedColumn[3].isChecked,
  },
  {
    title: translate("mybusiness.invoice.organisation"),
    type: "text",
    sortable: {
      sortIconName: defaultIconName,
    },
    isChecked: checkedColumn[4].isChecked,
  },
  {
    title: translate("mybusiness.invoice.organisation.number"),
    type: "text",
    sortable: {
      sortIconName: defaultIconName,
    },
    isChecked: checkedColumn[5].isChecked,
  },
  {
    title: translate("mybusiness.invoice.address"),
    type: "truncatable-text",
    sortable: {
      sortIconName: defaultIconName,
    },
    isChecked: checkedColumn[6].isChecked,
  },
  {
    title: translate("mybusiness.invoice.deliveryMethod"),
    type: "text",
    sortable: {
      sortIconName: defaultIconName,
    },
    isChecked: checkedColumn[7].isChecked,
  },
  {
    title: translate("mybusiness.invoice.deliveryMethod.deliveryEmail"),
    type: "text",
    sortable: {
      sortIconName: defaultIconName,
    },
    isChecked: checkedColumn[8].isChecked,
  },
];

export const defineColumnSortStates = (
  sortColumnDescending: (
    row1: GetAllBillingAccountsForTscidDTO,
    row2: GetAllBillingAccountsForTscidDTO
  ) => number,
  sortColumnAscending: (
    row1: GetAllBillingAccountsForTscidDTO,
    row2: GetAllBillingAccountsForTscidDTO
  ) => number
): IColumnSortStates => ({
  default: {
    state: "default",
    next: "descending",
    sortingFunction: null,
    iconName: "sorter",
  },
  descending: {
    state: "descending",
    next: "ascending",
    sortingFunction: sortColumnDescending,
    iconName: "arrow-down",
  },
  ascending: {
    state: "ascending",
    next: "default",
    sortingFunction: sortColumnAscending,
    iconName: "arrow-up",
  },
});

export const getStringSortNumber = (val1: string, val2: string): number => {
  if (val1 > val2) {
    return 1;
  }
  if (val1 < val2) {
    return -1;
  }
  return 0;
};

export const translateInvoiceDeliveryMethod = (
  translate: (key: string, optionalObject?: Record<string, string | number>) => string,
  invoiceDeliveryMethod: string | undefined
): string => {
  switch (invoiceDeliveryMethod) {
    case "POSTAL":
      return translate("mybusiness.letter");
    case "EMAIL":
      return translate("mybusiness.email");
    case "EDI":
      return "EDI";
    case "EDI_AND_LETTER":
      return translate("mybusiness.ediAndLetter");
    case "EINVOICE":
      return translate("mybusiness.einvoice");
    default:
      return "";
  }
};
