import { ILayout, IPagination, ISortableColumns } from "../interfaces/IState";

export const createPaginationState = (): IPagination => ({
  pageSize: 25,
  page: 1,
  pageTotal: 1,
});

export const createLayoutState = (): ILayout => ({
  error: false,
  loading: true,
  loadingTemplate: true,
  billingAccounts: {
    error: false,
    loading: false,
  },
});

export const createSortableColumns = (): ISortableColumns => ({
  active: {
    state: "default",
    iconName: "sorter",
    activeName: "",
    activeSortingKey: "",
  },
  default: {
    state: "default",
    iconName: "sorter",
  },
});

export const createOrganisationsState = () => ({
  tscidList: [],
  list: [
    {
      tscid: "",
      name: "",
      number: "",
    },
  ],
  selected: {
    tscid: "",
    name: "",
    number: "",
  },
  isVisible: true,
});
