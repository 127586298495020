import { ref } from "vue";

import { logError } from "@telia/b2x-logging";
import { currentLocale } from "@telia/b2b-i18n";
import { addNewToast } from "@telia/b2b-message-service";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";

import { FILTER_KEY } from "../components/b2b-account-filter/enums/filter_keys";

import { IBillingAccounts, IInvoiceDeliveryMethod, IOrganisation } from "../interfaces/IState";

import useOrganisations from "./organisations";
import useActiveFilters from "./active-filters";

import {
  fetchBillingAccount,
  exportBillingAccounts,
} from "../services/corp-customer-billing-accounts";

import { createLayoutState } from "../utils/create-state";
import { analytics, trackEventExportBillingAccounts } from "../utils/analytics";
import { translateMixin } from "../locale";

const layoutState = ref(createLayoutState());
const billingAccounts = ref<IBillingAccounts>({ list: [] });
const scopeId = ref<string>("");
const { organisations } = useOrganisations();
const { activeFilters, addFilter, removeFilter } = useActiveFilters();
const toastIdNr = ref(0);
const translate = translateMixin.methods.t;
const selectedOrganisation = ref<string>("");

const addSelectedOrgFilter = () => {
  const orgToSelect = _findOrganisationByTscId(selectedOrganisation.value);
  if (orgToSelect) {
    addFilter({
      key: FILTER_KEY.ORGANISATION,
      value: orgToSelect.number,
    });
  } else {
    _logError("Organisation in selector not found in organisations.value");
  }
};

const setAllOrganisationsSelected = () => {
  removeFilter({
    key: FILTER_KEY.ORGANISATION,
  });
};

const useBillingAccountState = () => {
  const initializeData = async (): Promise<void> => {
    await setScopeId();
    await _tryToFetchBillingAccounts();
    setSelectedOrganisationFromLocalStorage();
  };

  const exportBillingAccounts = (): void => {
    _tryToExportBillingAccounts();
  };

  const setSelectedOrganisation = async (event) => {
    if (event.detail) {
      if (event.detail === "ALL") {
        selectedOrganisation.value = "";
        setAllOrganisationsSelected();
      } else {
        selectedOrganisation.value = event.detail;
        addSelectedOrgFilter();
      }
    } else {
      _logError("Organisation tscid was not returned from b2b-layout");
    }
  };

  return {
    layoutState,
    billingAccounts,
    scopeId,
    exportBillingAccounts,
    initializeData,
    setSelectedOrganisation,
  };
};

export default useBillingAccountState;

const setSelectedOrganisationFromLocalStorage = () => {
  const selectedOrganisationFromLocalStorage = window.localStorage.getItem(
    `selectedOrganisation-${scopeId.value}`
  );
  if (selectedOrganisationFromLocalStorage) {
    selectedOrganisation.value = selectedOrganisationFromLocalStorage;
  }
};

const setScopeId = async () => {
  scopeId.value = await getScopeIdOrThrow();
};

const _tryToFetchBillingAccounts = async (): Promise<void> => {
  try {
    layoutState.value.loading = true;
    billingAccounts.value.list = await fetchBillingAccount(scopeId.value);
  } catch (error) {
    addNewToast(
      getToastId(),
      "error",
      translate("errorMessage.title"),
      translate("errorMessage.fetchBillingAccounts")
    );

    layoutState.value.billingAccounts.error = true;
    _logError("Failed to fetch invoice accounts");
  } finally {
    layoutState.value.loading = false;
    layoutState.value.loadingTemplate = false;
  }
};

const _logError = (message: string): void => {
  logError("b2b-billing-account-page", message);
};

const _tryToExportBillingAccounts = async () => {
  try {
    layoutState.value.error = false;
    trackEventExportBillingAccounts(analytics.action.INITIATED);

    const exportBillingAccountRequest = _createExportBillingAccountRequest();

    await exportBillingAccounts(scopeId.value, _getTcids(), exportBillingAccountRequest);
    trackEventExportBillingAccounts(analytics.action.COMPLETED);
  } catch {
    addNewToast(
      getToastId(),
      "error",
      translate("errorMessage.title"),
      translate("errorMessage.filter")
    );
    layoutState.value.error = true;
    _logError("Failed to fetch exportBillingAccounts");
    trackEventExportBillingAccounts(analytics.action.ERROR);
  }
};

const _createExportBillingAccountRequest = (): ExportBillingAccountRequestDTO => {
  const billingAccount = _getKeyValueIfSetInActiveFilters(FILTER_KEY.ACCOUNT_NUMBER);
  const {
    billingAddress,
    billingZipcode,
    billingCity,
  }: {
    billingAddress: string | undefined;
    billingZipcode: string | undefined;
    billingCity: string | undefined;
  } = _destructureAddress();
  const deliveryMethod = _getKeyValueIfSetInActiveFilters(
    FILTER_KEY.DELIVERY_METHOD
  ) as IInvoiceDeliveryMethod;
  const emailAddress = _getKeyValueIfSetInActiveFilters(FILTER_KEY.DELIVERY_EMAIL);
  const reference = _getKeyValueIfSetInActiveFilters(FILTER_KEY.ACCOUNT_REFERENCE);
  const co = _getKeyValueIfSetInActiveFilters(FILTER_KEY.ACCOUNT_CO);
  return {
    billingAccount,
    billingAddress,
    billingZipcode,
    billingCity,
    deliveryMethod,
    emailAddress,
    reference,
    co,
    currentLanguage: currentLocale().split("_")[0].toLowerCase(),
  };
};

const _getKeyValueIfSetInActiveFilters = (
  key: string
): string | IInvoiceDeliveryMethod | undefined => {
  const activeFilter = activeFilters.value.find((af) => af.key === key);
  return activeFilter ? activeFilter.value : undefined;
};

const _getTcids = (): string[] => {
  const isOrgNumberInActiveFilters = _getKeyValueIfSetInActiveFilters(FILTER_KEY.ORGANISATION);

  let org: IOrganisation | undefined;
  if (isOrgNumberInActiveFilters) {
    org = _findOrganisationByNumber(isOrgNumberInActiveFilters);
  }

  return org ? [org.tscid] : organisations.value.tscidList;
};

const _destructureAddress = (): {
  billingAddress: string | undefined;
  billingZipcode: string | undefined;
  billingCity: string | undefined;
} => {
  const address = _getKeyValueIfSetInActiveFilters(FILTER_KEY.BILLING_ADDRESS);
  let billingAddress: string | undefined,
    billingZipcode: string | undefined,
    billingCity: string | undefined,
    zipCodeAndCity: string | undefined = undefined;
  if (address) {
    [billingAddress, zipCodeAndCity] = address.split(",");
    billingZipcode = zipCodeAndCity.trim().substring(0, 6).replace(" ", "");
    billingCity = zipCodeAndCity.trim().substring(6).trim();
  }
  return { billingAddress, billingZipcode, billingCity };
};

const _findOrganisationByNumber = (organisationNumber: string): IOrganisation | undefined => {
  return organisations.value.list.find((org: IOrganisation) => org.number === organisationNumber);
};

const _findOrganisationByTscId = (organisationTscId: string): IOrganisation | undefined => {
  return organisations.value.list.find((org: IOrganisation) => org.tscid === organisationTscId);
};

const getToastId = () => {
  toastIdNr.value += 1;
  return `billingAccountStateToastId-${toastIdNr.value}`;
};
