import { ref } from "vue";
import { IPagination } from "../interfaces/IState";
import { trackEventInvoiceAccounts, analytics } from "../utils/analytics";
import { createPaginationState } from "../utils/create-state";

const pagination = ref<IPagination>(createPaginationState());

const usePagination = () => {
  const paginationChange = (detail: { page: number; pageSize: number }): void => {
    paginationChangeGoogleAnalytics(detail.page, detail.pageSize);
    changePage(detail.page, detail.pageSize);
  };

  const setPaginationToPageOne = (): void => {
    pagination.value.page = 1;
  };

  const pageSizeHasChanged = (newpageSize: number): boolean => {
    return newpageSize !== pagination.value.pageSize;
  };

  const changePage = (page: number, pageSize: number): void => {
    pagination.value.page = page;
    pagination.value.pageSize = pageSize;
  };

  const paginationChangeGoogleAnalytics = (page: number, pageSize: number): void => {
    const label = pageSizeHasChanged(pageSize)
      ? `Page size selected ${pageSize}`
      : `Page selected ${page}`;
    trackEventInvoiceAccounts(label, analytics.action.CLICK);
  };

  return { pagination, paginationChange, setPaginationToPageOne };
};

export default usePagination;
