<script setup lang="ts">
import { ref, computed } from "vue";

import useBillingAccountState from "../../../composables/billing-account-state";

import { FILTER_KEY } from "./../enums/filter_keys";

import { translateMixin } from "./../../../locale/index";
import useActiveFilters from "../../../composables/active-filters";

const t = translateMixin.methods.t;
const inputValue = ref("");
const accountCOFilterRef = ref();
const accountCOSuggestions = ref<string[]>([]);
const { billingAccounts, layoutState } = useBillingAccountState();
const { addFilter } = useActiveFilters();

const billingAccountsList = computed(() => {
  return billingAccounts.value.list;
});

const loading = computed(() => {
  return layoutState.value.billingAccounts.loading;
});

const disabled = computed(() => {
  return layoutState.value.loading;
});

const createAccountCOSuggestions = () => {
  setLoading(true);
  const matchingAccounts = billingAccountsList.value.filter((account) =>
    account.accountCO.toLowerCase().includes(inputValue.value.toLowerCase())
  );

  const invoiceCO = matchingAccounts.map((account) => {
    return account.accountCO;
  });

  accountCOSuggestions.value = [...new Set(invoiceCO)];
  setLoading(false);
};

const clearAccountCOSuggestions = () => {
  accountCOSuggestions.value = [];
};

const clearInput = () => {
  if (!accountCOFilterRef.value?.shadowRoot) return;

  accountCOFilterRef.value.shadowRoot.querySelector("input").value = "";
};

const setLoading = (isLoading: boolean) => {
  layoutState.value.billingAccounts.loading = isLoading;
};

const handleInputValue = (event: CustomEvent) => {
  inputValue.value = event.detail;
  if (inputValue.value.length > 2) {
    createAccountCOSuggestions();
  } else {
    clearAccountCOSuggestions();
  }
};

const handleSuggestionSelected = (event: CustomEvent) => {
  addFilter({
    key: FILTER_KEY.ACCOUNT_CO,
    value: event.detail,
  });
  clearInput();
  clearAccountCOSuggestions();
};
</script>

<template>
  <div t-id="account-co-filter">
    <b2x-input-suggestions
      t-id="test"
      ref="accountCOFilterRef"
      :label="t('mybusiness.invoice.organisation.accountCO')"
      :loading="loading"
      :disabled="disabled"
      :suggestions="JSON.stringify(accountCOSuggestions)"
      @inputValue="handleInputValue"
      @suggestionSelected="handleSuggestionSelected"
    ></b2x-input-suggestions>
  </div>
</template>
