import { ref } from "vue";

import { FILTER_KEY } from "../components/b2b-account-filter/enums/filter_keys";

import { IActiveFilter } from "../interfaces/IState";

import { translateMixin } from "../locale";

import { trackEventInvoiceAccounts, analytics } from "../utils/analytics";
import { translateInvoiceDeliveryMethod } from "../utils/helper_utils";

const activeFilters = ref<IActiveFilter[]>([]);
const translate = translateMixin.methods.t;

const invoiceDeliveryMethodFilterRef = ref();
const invoiceFilterOptionOrganisationRef = ref();

const useActiveFilters = () => {
  const addFilter = (filter: IActiveFilter): void => {
    _removeFilter(filter);
    activeFilters.value.push(filter);
    trackEventInvoiceAccounts(_createActiveFiltersLabel(), analytics.action.FILTER);
  };

  const removeFilter = (filter: IActiveFilter): void => {
    switch (filter.key) {
      case FILTER_KEY.DELIVERY_METHOD:
        resetDeliveryMethodFilter();
        break;
      default:
        break;
    }
    _removeFilter(filter);
    trackEventInvoiceAccounts(_createActiveFiltersLabel(), analytics.action.FILTER);
  };

  const removeAllFilters = (): void => {
    resetDeliveryMethodFilter();
    activeFilters.value.forEach((filter: IActiveFilter) => {
      filter.key !== FILTER_KEY.ORGANISATION && _removeFilter(filter);
    });

    trackEventInvoiceAccounts(analytics.action.CLEAR, analytics.action.FILTER);
  };

  return {
    activeFilters,
    invoiceDeliveryMethodFilterRef,
    invoiceFilterOptionOrganisationRef,
    addFilter,
    removeFilter,
    removeAllFilters,
  };
};

export default useActiveFilters;

const _removeFilter = (filter: IActiveFilter): void => {
  const newActiveFilters = activeFilters.value.filter(
    (activeFilter: IActiveFilter) => activeFilter.key !== filter.key
  );
  activeFilters.value = newActiveFilters;
};

const resetDeliveryMethodFilter = () => {
  if (!invoiceDeliveryMethodFilterRef.value || !invoiceDeliveryMethodFilterRef.value.querySelector)
    return;

  invoiceDeliveryMethodFilterRef.value
    .querySelector("select")
    .querySelectorAll("option")[0].selected = true;
};

const _createActiveFiltersLabel = () => {
  return activeFilters.value
    .map((activeFilter) => {
      switch (activeFilter.key) {
        case FILTER_KEY.DELIVERY_METHOD:
          return translateInvoiceDeliveryMethod(translate, activeFilter.value);
        default:
          return activeFilter.value;
      }
    })
    .join("+");
};
