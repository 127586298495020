<script setup lang="ts">
import { ref, computed } from "vue";

import useActiveFilters from "../../../composables/active-filters";
import useBillingAccountState from "../../../composables/billing-account-state";

import { FILTER_KEY } from "./../enums/filter_keys";

import { translateMixin } from "./../../../locale/index";

const t = translateMixin.methods.t;
const { layoutState } = useBillingAccountState();
const { invoiceDeliveryMethodFilterRef, addFilter, removeFilter } = useActiveFilters();

const deliveryMethodFilterOptions = computed(() => {
  return [
    {
      label: t("mybusiness.invoice.allDeliveryMethods"),
      value: t("mybusiness.invoice.allDeliveryMethods"),
      selected: true,
    },
    {
      label: t("mybusiness.letter"),
      value: "POSTAL",
      selected: false,
    },
    {
      label: t("mybusiness.email"),
      value: "EMAIL",
      selected: false,
    },
    {
      label: "EDI",
      value: "EDI",
      selected: false,
    },
    {
      label: t("mybusiness.ediAndLetter"),
      value: "EDI_AND_LETTER",
      selected: false,
    },
    {
      label: t("mybusiness.einvoice"),
      value: "EINVOICE",
      selected: false,
    },
  ];
});

const fieldIsDisabled = computed(() => {
  return layoutState.value.loading;
});

const filterOnDeliveryMethod = ({ target }) => {
  if (target.value === t("mybusiness.invoice.allDeliveryMethods")) {
    removeFilter({
      key: FILTER_KEY.DELIVERY_METHOD,
    });
  } else {
    addFilter({
      key: FILTER_KEY.DELIVERY_METHOD,
      value: target.value,
    });
  }
};
</script>

<template>
  <div t-id="invoice-delivery-method-filter">
    <telia-select
      ref="invoiceDeliveryMethodFilterRef"
      id="invoice-delivery-method-filter"
      t-id="invoice-delivery-method-filter-select"
      :label="t('mybusiness.invoice.deliveryMethod')"
      :options="JSON.stringify(deliveryMethodFilterOptions)"
      :disabled="fieldIsDisabled"
      @change="filterOnDeliveryMethod"
    >
    </telia-select>
  </div>
</template>
